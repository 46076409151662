<template>
  <div class="container">
    <div class="px-4 sm:px-8 md:px-16 lg:px-40 py-16 grid grid-cols-1 gap-8 xl:gap-12">
      <div class="card-detail">
        <div v-if="CARD_ACTIVE || CARD_NEW || CARD_PENDING || CARD_SUSPENDED || CARD_LOCKED"
          class="card-wrap flex flex-wrap">
          <div class="heading card-number w-full text-primary text-2xl leading-7 xl:text-4xl xl:leading-11">
            {{ SEPARATED_CARD_NUMBER }}
          </div>
          <div class="flex flex-nowrap justify-between w-full">
            <div v-if="CARD_NEW" class="balance flex flex-col justify-end w-0 grow text-white">
              <div class="text-xl xl:text-3xl font-semibold mb-1">{{ $t('card.first.how-to') }}</div>
              <div class="font-semibold">
                <i18n path="card.first.top-up-2" tag="span">
                  <router-link v-if="!CARD_EXPIRED && !CARD_SUSPENDED"
                    :to="localePath({ name: 'topup', params: { id: CURRENT_ID } })" class="text-primary">{{
                      $t('card.first.top-up-1') }}</router-link>
                </i18n>
                <!-- <router-link :to="localePath({ name: 'topup' })" class="text-primary">{{ $t('card.first.top-up-1') }}</router-link>{{ $t('card.first.top-up-2') }} -->
              </div>
              <!-- <div class="text-white heading text-3xl leading-[38px] xl:text-5xl xl:leading-[56px] whitespace-nowrap truncate">$ {{ toCurrencyString(CARD_DETAIL.balance, true) || '0.0' }}</div> -->
            </div>
            <div v-else-if="!CARD_EXPIRED && !CARD_SUSPENDED" class="balance flex flex-col justify-end w-0 grow">
              <div class="font-semibold">
                {{ $t('card.balance') }}
                <span class="whitespace-nowrap text-xs leading-5 xl:text-base"> ({{ $t('card.balance-max') }} $ {{
                  toCurrencyString(CARD_DETAIL.balanceUpper) }})</span>
              </div>
              <div
                class="text-white heading text-3xl leading-[38px] xl:text-5xl xl:leading-[56px] whitespace-nowrap truncate">
                $ {{ toCurrencyString(CARD_DETAIL.balance, true) || '0.0' }}</div>
            </div>
            <div class="balance flex flex-col justify-end items-end  ml-2">
              <div><button v-if="!CARD_EXPIRED && !CARD_SUSPENDED && !CARD_LOCKED" class="btn btn-sm whitespace-nowrap"
                  :class="{ disabled: CARD_TOPUP_DISABLED }" :disabled="CARD_TOPUP_DISABLED" @click="gotoTopUpPage">{{
                    $t('button.top-up') }}</button></div>
            </div>
          </div>
        </div>
        <div v-if="CARD_WAITING_TO_GET" class="card-wrap flex flex-col justify-between">
          <div class="heading text-white text-xl xl:text-3xl xl:leading-[38px]">{{ $t('card.check-detail') }}</div>
          <div class="flex gap-5 xl:gap-10">
            <div v-html="$t('card.info-notice')" class="text-white text-xs xl:text-base font-semibold">
              <!-- Please be aware that the card information will show only one time and <span class="text-primary">CAN NOT</span> be retrieved after dismissed. -->
            </div>
            <div class="self-end">
              <button type="button" class="
                  transition ease-in-out delay-150 duration-150
                  bg-white
                  hover:bg-[#F4F4F4]
                  text-[#FF0000]
                  font-semibold
                  px-3 py-4 xl:px-4 xl:py-5
                  leading-5
                  rounded-2xl
                  whitespace-nowrap
                " @click="handleCheckClick">{{ $t('button.check') }}</button>
            </div>
          </div>
        </div>
        <div class="description-wrap">
          <div class="mb-10">
            <div class="float-right w-20 text-right">
              <div class="card-status pt-1"
                :class="{ 'text-primary': CARD_ACTIVE && !CARD_EXPIRED && !CARD_SUSPENDED && !CARD_LOCKED }">
                <span v-if="CARD_EXPIRED" class="mr-1">{{ $t('card.status.expired') }}</span>
                <span v-else-if="CARD_SUSPENDED" class="mr-1">{{ $t('card.status.suspended') }}</span>
                <span v-else-if="CARD_LOCKED">{{ $t('card.status.locked') }}</span>
                <span v-else-if="CARD_ACTIVE">{{ $t('card.status.activated') }}</span>
                <span v-else>{{ $t('card.status.not-activated') }}</span>
              </div>
              <label v-if="!CARD_EXPIRED && !CARD_SUSPENDED && SUPPORT_LOCK"
                class="relative inline-flex items-center cursor-pointer pt-3 justify-end">
                <input class="sr-only peer" :checked="CARD_LOCKED" type="checkbox" @click="toggleLock">
                <div
                  class="group ring-0 bg-gray-50 border-2 border-gray-400 rounded-full outline-none duration-700 after:duration-200 w-20 h-10 shadow-md peer-checked:bg-gradient-to-r  peer-focus:outline-none  after:content-[''] after:rounded-full after:absolute after:bg-gray-900 after:outline-none after:h-8 after:w-8 after:top-4 after:left-1  peer-checked:after:translate-x-10 peer-hover:after:scale-95">
                  <svg y="0" xmlns="http://www.w3.org/2000/svg" x="0" width="100" viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid meet" height="100"
                    class="absolute  top-4 left-10 fill-green-600 w-8 h-8">
                    <path
                      d="M50,18A19.9,19.9,0,0,0,30,38v8a8,8,0,0,0-8,8V74a8,8,0,0,0,8,8H70a8,8,0,0,0,8-8V54a8,8,0,0,0-8-8H38V38a12,12,0,0,1,23.6-3,4,4,0,1,0,7.8-2A20.1,20.1,0,0,0,50,18Z"
                      class="svg-fill-primary">
                    </path>
                  </svg>

                  <svg y="0" xmlns="http://www.w3.org/2000/svg" x="0" width="100" viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid meet" height="100"
                    class="absolute top-4 left-1 fill-red-600  w-8 h-8">
                    <path fill-rule="evenodd"
                      d="M30,46V38a20,20,0,0,1,40,0v8a8,8,0,0,1,8,8V74a8,8,0,0,1-8,8H30a8,8,0,0,1-8-8V54A8,8,0,0,1,30,46Zm32-8v8H38V38a12,12,0,0,1,24,0Z">
                    </path>
                  </svg>
                </div>
              </label>
            </div>

            <div class="text-dark font-semibold">{{ $t('card.pending-top-up') }}</div>
            <div class="text-dark heading text-4xl pt-3">$ {{ toCurrencyString(CARD_DETAIL.pendingTopup, true) || '0.0' }}</div>
          </div>
          <p v-if="CARD_EXPIRED" class="mb-[22px] font-semibold">
            <i18n path="card.notice.expired-instruction" tag="span">
              <a class="text-link whitespace-nowrap" href="mailto:service@paywide.io">service@paywide.io</a>
            </i18n>
          </p>
          <p v-else-if="CARD_SUSPENDED">
            <i18n path="card.notice.suspended-instruction" tag="span">
              <a class="text-link whitespace-nowrap" href="mailto:service@paywide.io">service@paywide.io</a>
            </i18n>
          </p>
          <p v-else-if="!CARD_LOCKED" class="mb-[22px] font-semibold">
            {{ $t('card.notice.title') }} <br />
            {{ $t('card.notice.content') }}
          </p>
          <div v-if="CARD_ACTIVE && !CARD_EXPIRED" class="links flex flex-col sm:flex-row gap-2 self-end">
            <div class="order-first sm:order-last flex gap-2">
              <button class="btn" @click="get3DS">{{ $t('button.3ds') }}</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="CARD_STATUS"
        class="history-wrap xs:w-full -mx-4 xs:mx-auto xs:mb-0 md:mx-auto -mb-16 bg-gray-100 xs:rounded-3xl min-h-[450px]">
        <div class="tabs">
          <ul class="flex flex-nowrap w-full justify-start">
            <li ref="tab-0-width"
              :class="['tab', 'flex-auto', 'items-center', 'mix-blend-multiply', { 'active': activeTab === 'transaction' }]"
              @click="activeTab = 'transaction'">
              <span>{{ $t('card.tab.transaction-history') }}</span>
            </li>
            <li ref="tab-1-width"
              :class="['tab', 'flex-auto', 'mix-blend-multiply', { 'active': activeTab === 'unrecorded' }]"
              @click="activeTab = 'unrecorded'">
              <span>{{ $t('card.tab.unsettled-transactions') }}</span>
            </li>
          </ul>
        </div>
        <div v-if="activeTab === 'transaction'" class="tab-content">
          <div class="px-6 py-2">
            <TransactionHistory />
          </div>
        </div>
        <div v-if="activeTab === 'unrecorded'" class="tab-content">
          <div class="px-6 py-2">
            <UnrecordedHistory />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import CustomTable from '@/components/CustomTable'
import TransactionHistory from '@/components/TransactionHistory.vue'
import UnrecordedHistory from '@/components/UnrecordedHistory.vue'
import { toCurrencyString } from '@/utils/currency-helper'
import { lockCard, unlockCard } from '@/utils/api/card'

export default {
  name: 'CardView',
  components: {
    TransactionHistory,
    UnrecordedHistory
  },
  data () {
    return {
      activeTab: 'transaction',
      'tab-0-width': null,
      'tab-1-width': null
    }
  },
  computed: {
    ...mapGetters('CARD', [
      'CARD_NEW',
      'CARD_PENDING',
      'CARD_ACTIVE',
      'CARD_STATUS',
      'CARD_DETAIL',
      'CARD_WAITING_TO_GET',
      'SEPARATED_CARD_NUMBER',
      'CURRENT_ID',
      'CARD_EXPIRED',
      'CARD_SUSPENDED',
      'CARD_TOPUP_DISABLED',
      'CARD_LOCKED',
      'SUPPORT_LOCK'
    ])
  },
  async mounted () {
    // this['tab-0-width'] = this.$refs['tab-0-width'].clientWidth
    // this['tab-1-width'] = this.$refs['tab-1-width'].clientWidth
    await this.$store.dispatch('CARD/GET_CARD')
    if (this.CARD_PENDING) {
      this.$store.dispatch('MODAL/OPEN', 'WaitingConfirm')
    }
  },
  methods: {
    toCurrencyString,
    handleCheckClick () {
      this.$store.dispatch('MODAL/OPEN', 'CardImage')
    },
    get3DS () {
      this.$store.dispatch('MODAL/OPEN', 'Card3DSecure')
    },
    gotoTopUpPage () {
      const path = this.localePath({ name: 'topup', params: { id: this.CURRENT_ID } })
      this.$router.push(path)
    },
    toggleLock: async function () {
      if (!this.SUPPORT_LOCK) {
        return
      }
      try {
        const params = { id: this.CURRENT_ID }
        if (this.CARD_LOCKED) {
          await unlockCard(params)
        } else {
          await lockCard(params)
        }
        await this.$store.dispatch('CARD/GET_CARD')
      } catch (e) {
        const error = e.response.data.message
        this.$store.dispatch('MODAL/ALERT', {
          title: this.$t('card.lock.alert.error-title'),
          text: error,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-detail {
  // @apply grid grid-cols-1 sm:grid-cols-2;
  @apply gap-6 md:gap-10;
  @apply flex flex-wrap items-start lg:flex-row;

  .card-wrap {
    @apply bg-dark aspect-card overflow-hidden;
    // @apply h-12;
    @apply max-w-full w-80 xl:w-[30rem];
    @apply mx-auto;
    // @apply w-full;
    @apply rounded-2xl xl:rounded-[2rem];
    @apply p-5 xl:p-10;
    @apply text-left;
  }

  .description-wrap {
    @apply flex flex-col justify-between;
    @apply min-w-[220px];
    @apply flex-1;
    // @apply w-full;
    @apply text-left;

  }
}

.tabs {
  @apply relative;
  @apply flex;
  @apply border-black dark:border-gray-700;

  .tab {
    @apply flex justify-center items-center;
    @apply px-1 py-4 w-0 grow max-w-[33.333333%] lg:max-w-[25%];
    @apply cursor-pointer font-semibold;

    &:hover {
      @apply text-primary;
    }

    &.active {
      @apply text-primary relative;

      &::after {
        content: '';
        @apply absolute z-10;
        @apply border border-primary;
        @apply top-full left-0;
        @apply w-full;
        @apply transition-all ease-linear duration-100;
      }
    }
  }

  &::before {
    content: '';
    @apply absolute;
    @apply border border-white;
    @apply top-full left-0;
    @apply w-full;
  }

  .active-bar {
    @apply absolute z-10;
    @apply bg-primary;
    @apply top-full;
    @apply h-[2px];
    @apply transition-all ease-linear duration-100;
  }

  // &::after {
  //   content: '';
  //   @apply absolute;
  //   @apply bg-white;
  //   @apply top-full left-0;
  //   @apply h-[2px] w-full;
  // }
}

.card-status {
  @apply text-xs;

  &::after {
    content: '';
    @apply bg-current;
    @apply inline-block w-4 h-4 rounded-full align-sub ml-2;
  }
}
</style>
